import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const RecaptchaPopout: React.FC<{ returnResponse: (response: { verified: boolean, footprint?: string }) => void }> = ({ returnResponse }): JSX.Element => {
   // Hardcoded site key (this is public, so it's safe)
   const siteKey = "6LcyO94qAAAAAKn3QrCnbu96TrftdpMV7uSD-t_g"; // Replace with your actual site key

   console.log("Recaptcha site key:", siteKey);

   const handleRobotVerification = (response: string | null): void => {
      if(response === null) {
         returnResponse({
            verified: false
         });
      } else {
         returnResponse({
            verified: true,
            footprint: response
         });
      }
   };

   return (
      <>
         <div className="modal-backdrop show">
            <div className="standard-modal-container">
               <h2>Are you a human?</h2>
               <p>Please pass this quick test to prove that you are not a robot.</p>

               <ReCAPTCHA
                  sitekey={siteKey}
                  onChange={(e) => handleRobotVerification(e)}
                  translate="yes"
                  onError={(e) => console.log(e)}
               />
            </div>
         </div>
      </>
   );
};

export default RecaptchaPopout;
